import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { IProduct, ISanityArticle } from '@vb/shared/interfaces';

import { AppHelperService } from './app-helper.service';

interface IObjectKeys {
    [key: string | number]: string | number | undefined;
}

const JSONLD_SCRIPT_TAG_ID = '_vb-jsonld';

const PRODUCT_SCORE_MAP: IObjectKeys = {
    5: '5',
    4: '4.5',
    3: '4'
};

const VINBORSEN_ORGANIZATION_SCHEMA = {
    '@type': 'Organization',
    email: 'info(at)vinborsen.se',
    name: 'Vinbörsen',
    address: {
        '@type': 'PostalAddress',
        addressLocality: 'Stockholm, Sweden',
        postalCode: '11138',
        streetAddress: 'Brunnsgatan 8'
    }
};

export interface IJsonLDBase {
    '@context': string;
    '@type': string;
    name?: string;
    url?: string;
    logo?: string;
    description?: string;
}
export interface IJsonLdUpdatePayload extends IJsonLDBase {
    itemListElement?: IJsonItemListElement[];
}

export interface IJsonItemListElement {
    '@type'?: string;
    position?: number;
    name?: string;
    item?: string;
}

export interface IJsonLdProductPayload extends IJsonLDBase {
    image: string[];
    gtin: string;
    productID: string;
    sku: string;
    offers: IJsonProductOffers;
    aggregateRating?: IJsonProductAggregateRating;
    brand?: IJsonProductBrand;
    material?: string;
    countryOfOrigin?: IJsonProductCountryOfOrigin;
}

export interface IJsonProductOffers {
    '@type': string;
    url: string;
    priceCurrency: string;
    priceValidUntil: string;
    itemCondition: string;
    availability: string;
    price?: string;
    priceSpecification?: IJsonProductOffersPriceSpecification[];
}

export interface IJsonProductOffersPriceSpecification {
    '@type': string;
    price: string;
    validThrough?: string;
    validFrom?: string;
    priceCurrency: string;
}

export interface IJsonProductAggregateRating {
    '@type': string;
    ratingValue: string;
    reviewCount: string;
}

export interface IJsonProductBrand {
    '@type': string;
    name: string;
}

export interface IJsonProductCountryOfOrigin {
    '@type': string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class AppJsonLdService {
    constructor(
        @Inject(DOCUMENT) private dom: Document,
        private _appHelper: AppHelperService
    ) {}

    update(payload?: IJsonLdUpdatePayload[]) {
        let el: HTMLElement = this.dom.getElementById(JSONLD_SCRIPT_TAG_ID);

        if (!el) {
            el = this.dom.createElement('script');
            el.setAttribute('id', JSONLD_SCRIPT_TAG_ID);
            el.setAttribute('type', 'application/ld+json');
            this.dom.head.appendChild(el);
        }

        try {
            (el as HTMLScriptElement).text = JSON.stringify(payload);
        } catch (error) {
            console.log(error);
        }
    }

    article(article: ISanityArticle) {
        const payload: IJsonLdUpdatePayload[] = [];

        const url = this._appHelper.getCurrentUrl();
        const headline = article.title;
        const abstract = article.excerpt;
        const image = this._appHelper.generateSanityImage(article.image);
        const datePublished = article.publishedAt || article._createdAt;
        const dateModified = article._updatedAt;
        const author = article.author?.name
            ? {
                  '@type': 'Person',
                  name: article.author.name
              }
            : VINBORSEN_ORGANIZATION_SCHEMA;

        const articlePayload = {
            '@context': 'https://schema.org/',
            '@type': 'Article',
            url,
            headline,
            abstract,
            author: [author],
            datePublished,
            dateModified,
            image: [image['1x1'], image['4x3'], image['16x9']]
        };

        payload.push(articlePayload);

        this.update(payload);
    }

    product(product: IProduct) {
        const payload: IJsonLdUpdatePayload[] = [];

        const url = this._appHelper.getCurrentUrl();
        const name = this._appHelper.generateProductTitle(product);
        const description = this._appHelper.generateProductDescription(product);
        const image = this._appHelper.generateProductImage(product);

        const productPayload: IJsonLdProductPayload = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            url,
            name,
            image: [image['1x1'], image['4x3'], image['16x9']],
            description,
            gtin: product.gtinPrimary,
            productID: product.productId,
            sku: product.productId,
            offers: {
                '@type': 'Offer',
                url: this._appHelper.getCurrentUrl(),
                priceCurrency: 'SEK',
                priceValidUntil: '2029-12-31',
                itemCondition: 'https://schema.org/NewCondition',
                availability: product._outOfStock
                    ? 'https://schema.org/OutOfStock'
                    : 'https://schema.org/InStock'
            }
        };

        if (product.isDiscounted && product.lastDiscountedAt) {
            const validFrom = new Date(product.lastDiscountedAt);
            const validThrough = new Date(
                new Date(product.lastDiscountedAt).setDate(validFrom.getDate() - 1)
            );
            productPayload.offers.priceSpecification = [
                {
                    '@type': 'PriceSpecification',
                    price: parseFloat(product.priceOriginal.toString()).toFixed(2),
                    validThrough: validThrough.toISOString(),
                    priceCurrency: 'SEK'
                },
                {
                    '@type': 'PriceSpecification',
                    price: parseFloat(product.price.toString()).toFixed(2),
                    validFrom: validFrom.toISOString(),
                    priceCurrency: 'SEK'
                }
            ];
        } else {
            productPayload.offers.price = parseFloat(product.price.toString()).toFixed(2);
        }

        if (product.score) {
            productPayload.aggregateRating = {
                '@type': 'AggregateRating',
                ratingValue: PRODUCT_SCORE_MAP[product.score] as string,
                reviewCount: '1'
            };
        }

        if (product.producer && product.producer.name) {
            productPayload.brand = {
                '@type': 'Brand',
                name: product.producer.name
            };
        }

        if (product?.details?.grapes) {
            productPayload.material = product?.details?.grapes.join(', ');
        }

        if (product?.origin?.country) {
            productPayload.countryOfOrigin = {
                '@type': 'Country',
                name: product?.origin?.country
            };
        }

        payload.push(productPayload);

        if (product.category && product.category.level2) {
            const LEVEL_1_MAP: IObjectKeys = {
                Vin: 'vin',
                Öl: 'ol',
                'Cider & blanddrycker': 'cider-blanddrycker'
            };

            const LEVEL_2_MAP: IObjectKeys = {
                Rött: 'rott',
                Vitt: 'vitt',
                Rosé: 'rose',
                Mousserande: 'mousserande',

                Ale: 'ale',
                'Ljus lager': 'ljus-lager',
                'Syrlig öl': 'syrlig-ol',
                'Porter & Stout': 'porter-stout',
                'Mellanmörk & Mörk lager': 'mellanmork-mork-lager',

                Cider: 'cider',
                Blanddrycker: 'blanddrycker'
            };

            const level1Slug = LEVEL_1_MAP[product.category?.level1];
            const level2Slug = LEVEL_2_MAP[product.category?.level2];

            const itemListElement: IJsonItemListElement[] = [];

            if (level1Slug) {
                itemListElement.push({
                    '@type': 'ListItem',
                    position: 1,
                    name: product.category.level1,
                    item: `https://vinborsen.se/hitta/typ/${level1Slug}`
                });
            }

            if (level1Slug && level2Slug) {
                itemListElement.push({
                    '@type': 'ListItem',
                    position: 2,
                    name: product.category.level2,
                    item: `https://vinborsen.se/hitta/typ/${level1Slug}/${level2Slug}`
                });
            }

            itemListElement.push({
                '@type': 'ListItem',
                position: itemListElement.length + 1,
                name: product.title
            });

            payload.push({
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement
            });
        }

        this.update(payload);
    }
}
